<template>
	<div class="change-pwd">
		<van-nav-bar
			title="修改密碼"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<div class="form qjc-texta-l">
			<van-field
				v-model="oldPwd"
				type="password"
				placeholder="請輸入您的舊密碼"
				label="舊密碼"
			></van-field>
			
			<van-field
				v-model="newPwd"
				type="password"
				placeholder="請輸入您的新密碼"
				label="新密碼"
			></van-field>
			
			<van-field
				v-model="newPwd2"
				type="password"
				placeholder="請確認您的新密碼"
				label="確認新密碼"
			></van-field>
			
			<van-button @click="changePwd" :loading="submiting" class="login-btn qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
				<span class="qjc-vertical-m">修改密碼</span>
				<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
			</van-button>
			
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(Toast);
	   
	import { perfectInfo } from '@/js/axios'
	
	export default{
		name: 'agentChangePwd',
		data() {
			return {
				oldPwd: '',
				newPwd: '',
				newPwd2: '',
				
				submiting: false
			}
		},
		methods: {
			changePwd() {
				if(!this.oldPwd.trim()){
					Toast.fail('請輸入您的舊密碼');
				}else if(!this.newPwd.trim()){
					Toast.fail('請輸入您的新密碼');
				}else if(!this.newPwd2.trim()){
					Toast.fail('請確認您的新密碼');
				}else if(this.newPwd != this.newPwd2){
					Toast.fail('兩次輸入新密碼不一致');
				}else{
					this.submiting = true;
					perfectInfo({
						password: this.oldPwd,
						newpassword: this.newPwd
					}).then(res => {
						// console.log(res)
						this.submiting = false;
						if(res.code == 200){
							Toast.success('修改成功');
							this.$store.dispatch('agentExit').then(() => {
								this.$router.push('/agent/login');
							});
						}else{
							Toast.fail(res.msg);
						}
					});
				}
			}
		}
	}
</script>

<style scoped>
	.form >>> .van-field__label{
		color: #8C9699;
	}
	.form >>> .van-field__control{
		text-align: right;
	}
</style>
<style lang="scss" scoped>
	.change-pwd{
		.form{
			padding: 0.4rem 0.24rem;
			
			.van-field{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				font-size: 0.3rem;
				border: 0.01rem solid #E1E3EB;
				border-radius: 0.08rem;
				margin-bottom: 0.2rem;
				padding: 0 0.24rem;
			}
			
			.login-btn{
				width: 7.02rem;
				height: 0.87rem;
				line-height: 1;
				border-radius: 0.08rem;
				margin: 0.6rem 0 0.18rem;
				padding: 0 0 0 0.36rem;
				
				img{
					width: 0.59rem;
					height: auto;
					margin: -0.1rem 0 0 0.11rem;
				}
			}
		}
	}
</style>
